<template>
  <div v-if="isDesktop">
    <desktop-view></desktop-view>
  </div>
  <div v-else>
    <mobile-view></mobile-view>
  </div>
</template>

<script>
import desktopView from "./components/desktop-view.vue";
import mobileView from "./components/mobile-view.vue";
import download from "./download";

export default {
  components: { desktopView, mobileView },
  mixins: [download],
   
  computed: {
    isDesktop() {
    
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
};
</script>

<style>
.vue-tel-input * {
  z-index: 22 !important;
  /* color: white !important; */
}

.vti__dropdown-list.below {
  padding-left: 0px;
}

.vti__dropdown-list.below * {
  color: black !important;
}
</style>

<style scoped>
.curved-container {
  width: 150%;
  height: 50%;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  bottom: 0px;
  left: -25%;
  position: absolute;
  z-index: 0;
}
</style>

