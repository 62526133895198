<template>
  <v-container
    class="pa-0 white"
    style="color: #efefef; height: 100vh"
    :style="isTallDisplay ? 'max-height: 600px;' : ''"
    fluid
  >
    <v-row class="fill-height" no-gutters>
      <v-col align-self="end" cols="6" class="d-none d-sm-flex justify-center"  style="width: 100%" align="center">
        <div style="height: 100vh">
          <v-img
            eager 
            align-self="center"
            contain
            :src= "localizedImage"
            position="center"
            height="100%"
          />
        </div>
      </v-col>
      <v-col align-self="center" cols="5">
        <div
          class="text-center mx-auto"
          style="max-width: 450px; font-size: 14px"
        >
          <div style="width: 100%; height: 80px"></div>
          <progress-indicator
            class="center-in-col"
            :steps="4"
            :currentStep="4"
          ></progress-indicator>
          <v-img
            class="mx-auto mt-16"
            contain
            src="@/assets/tick.png"
            lazy-src="@/assets/tick-lo.png"
            :eager="true"
            position="center"
            max-width="100px"
          />
          <div class="font-weight-bold text-h5 my-8 primary--text">
            {{ titleText }}
          </div>

          <div v-if="!existingDealWasBest">
            <div
              v-if="isUpgradeAccount"
              class="mt-4 font-weight-regular tertiaryGrey--text"
            >
              {{ stringForKey("newOfferBenefit") }}
            </div>
            <div class="mt-4 main-text tertiaryGrey--text" v-if="showEmail">
              {{ stringForKey("confirmationEmail") }}
            </div>
            <div
              class="mt-2 font-weight-bold primary--text main-text"
              v-if="showEmail"
            >
              {{ email }}
            </div>
          </div>
          <div v-else>
            <div class="mt-4 main-text tertiaryGrey--text">
              {{ stringForKey("thanksFor") }}
            </div>
          </div>

          <div
            v-if="!isUpgradeAccount && showPhoneField()"
            class="tertiaryGrey--text mt-8 mb-4 d-flex align-center flex-column"
          >
            <div class="pb-2 main-text" style="width: 300px">
              {{ stringForKey("smsNumber") }}
            </div>
            <div class="d-flex mt-2">
              <vue-tel-input
              z-index="1000"
                class="ml-8 primary--text"
                v-model="phone"
                @validate="validate"
                style="min-width: 280px"
                :dropdownOptions="{
                  showDialCodeInSelection: true,
                  showFlags: true,
                }"
                :inputOptions="{
                  placeholder: inputOptions,
                }"
              ></vue-tel-input>
              <v-btn
                @click="this.sendSMS"
                color="primary"
                icon
                light
                :loading="loading"
                ><v-icon>mdi-send</v-icon></v-btn
              >
     
            </div>
            <v-alert
              v-if="sent"
              type="success"
              border="left"
              dense
              dark
              color="rgba(0, 255, 196, 1)"
              class="ma-0 my-3 mx-8"
              style="max-width: 500px"
            >
              <div class="black--text">{{ stringForKey("smsSent") }}</div>
            </v-alert>
            <v-alert
              v-else-if="error"
              type="error"
              border="left"
              text
              dense
              class="ma-0 my-3 mx-8"
              style="max-width: 500px"
            >
              {{ stringForKey("smsError") }}
            </v-alert>
          </div>
          <div v-else>
            <div
              class="
                tertiaryGrey--text
                mt-4
                mb-4
                d-flex
                align-center
                flex-column
              "
            >
              <div v-if="requiresCancellation"
                class="main-text tertiaryGrey--text mt-4"
                v-html="requiresCancellationCopy"
              >
              </div>
              <div v-else
                class="main-text tertiaryGrey--text mt-4"
                style="width: 300px"
              >
              {{ stringForKey("downloadInstructions") }}
              </div>
            </div>
          </div>
          <div style="justify-content: center;">
            <div
              style="height: 40px;display: inline-block;padding: 4px 4px 4px 4px;"
              @click="downloadIos"
              :loading="loading"
            >
              <img src=@/assets/apple-store-download.png height="40px">
          </div>
            <div
              style="height: 40px;display: inline-block; padding: 4px 4px 4px 4px;"
              @click="downloadAndroid"
              :loading="loading"
            >
              <img src=@/assets/play-store-download.png height="40px">
          </div>
          </div>
        </div>
        <v-alert
          :style="`position: absolute; top: ${
            isDesktop ? 0 : 50
          }px; left: 0px; right: 0px`"
          class="ma-0"
          type="warning"
          text
          dense
          v-if="showEmailError"
          >{{ stringForKey("smsDelays") }}</v-alert
        >
        <!-- <div class="text-center pa-2 text-caption mt-10" style="width: 100%">
          <div v-if="subscriptionId">
            <div class="font-weight-regular" style="color: #bcbcbc">
              Subscription ID:
              <span>{{ subscriptionId }}</span>
            </div>
          </div>
          <div
            class="font-weight-regular mx-auto"
            style="width: 80%; color: #bcbcbc"
          >
            <span>User ID: {{ userId }}</span>
          </div>
        </div> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import download from "../download";
import { mapGetters } from "vuex";
import { getStringForKey } from "@/translations/utilities";

export default {


  mixins: [download],
  computed: {
    ...mapGetters({
      selectedLocale: "account/getSelectedLocale",
      location: "account/country",
    }),
    showEmail() {
      console.log(
        `Subscription ${this.subscriptionId} has deal ${this.hasDeal}`
      );
      return this.subscriptionId && !this.hasDeal;
    },
    inputOptions() {
      return getStringForKey(this.selectedLocale, 'enterPhoneNumber')
      // return {
      //   options: { placeholder: getStringForKey(this.selectedLocale, 'enterPhoneNumber') }
      // }
    },
    localizedImage() {
      switch (this.selectedLocale.key) {
        case "es":
          return require("@/assets/desktop-redeem-es.png");
        case "pt":
          return require("@/assets/desktop-redeem-pt.png");
        case "en":
          return require("@/assets/desktop-redeem.png");
      }
    },
  },
  methods: {
    stringForKey(key) {
      return getStringForKey(this.selectedLocale, key);
    },
    showPhoneField() {
      return (
        !this.requiresCancellation &&
        (this.location === "UK" ||
        this.location === "GB" ||
        this.location === "US" ||
        this.location === "USA")
      );
    },
    getHintText() {
      return getStringForKey(this.selectedLocale, 'enterPhoneNumber')
    },
    downloadIos() {
      setTimeout(() => {
        window.open(
          "https://apps.apple.com/us/app/withu-audio-fitness-app/id1487259038",
          "_self"
        );
      });
    },
    downloadAndroid() {
      setTimeout(() => {
        window.open(
          "https://play.google.com/store/apps/details?id=fit.withu.flutter.withu&hl=en_GB&gl=US",
          "_self"
        );
      });
    },
  },
};


</script>

<style scoped>
.v-alert .v-alert__icon.v-icon {
  color: black;
}
.main-text {
  font-size: 16px;
  font-weight: 500;
}
</style>
