import { mapState, mapActions, mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons/faCheckCircle";
import progressIndicator from "@/components/progress-indicator";
import { getStringForKey } from "@/translations/utilities";
import * as subs from "@/store/modules/account/subscriptionConstants";
import { generateBranchLoginLink} from "@/helpers/utilities";

const VueTelInput = () =>
  Promise.all([
    import(/* webpackChunkName: "chunk-vue-tel-input" */ "vue-tel-input"),
    import(
      /* webpackChunkName: "chunk-vue-tel-input" */ "vue-tel-input/dist/vue-tel-input.css"
    ),
  ]).then(([{ VueTelInput }]) => VueTelInput);

export default {
  name: "Download",
  components: {
    FontAwesomeIcon,
    VueTelInput,
    progressIndicator,
  },
  data() {
    return {
      error: false,
      sent: false,
      checkIcon: faCheckCircle,
      phone: null,
      valid: false,
      number: null,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    ...mapGetters({
      hasDeal: "account/hasDeal",
      originalLink: "account/originalLink",
      userFirstName: "account/firstName",
      fullName: "account/fullName",
      upgradeInfo: "account/upgrade",
      existingAccountToSubscriber: "account/existingAccountToSubscriber",
      existingDealWasBest: "account/existingDealWasBest",
      selectedLocale: "account/getSelectedLocale",
      isPreRegToken: "account/isPreRegToken",
      cancellationInstructions: "account/getCancellationInstructions",
    }),
    successfulVerify() {
      let verify = this.account.download;
      return verify.link != null && verify.code != null;
    },

    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    loading() {
      return this.account.loading;
    },
    userId() {
      return this.account.register.user_id;
    },
    subscriptionId() {
      if (this.account.stripe) {
        if (this.account.stripe.subscription) {
          return this.account.stripe.subscription.id;
        } else if (this.account.stripe.invoice) {
          return this.account.stripe.invoice.subscription;
        }
      }
      return null;
    },
    email() {
      return this.account.register.email;
    },
    showEmailError() {
      //PS: 120523 disabled
      // if (!this.email) return false;
      // var arr = ["ymail", "yahoo", "aol", "sky", "rocketmail"];
      // var hasDomain = false;
      // arr.forEach((d) => {
      //   if (this.email.includes(d)) {
      //     hasDomain = true;
      //   }
      // });
      // return hasDomain;
      return false;
    },
    firstName() {
      return this.userFirstName ?? "";
    },
    isUpgradeAccount() {
      let info = this.upgradeInfo;
      return info.canUpgrade;
    },
    requiresCancellation() {
      const cancellation = this.cancellationInstructions;
      return cancellation.requiresCancellation;
    },
    requiresCancellationStore() {
      const cancellation = this.cancellationInstructions;
      return cancellation.store;
    },
    requiresCancellationCopy() {
      switch (this.requiresCancellationStore) {
        case subs.subsciptionPaymentHandler.STRIPE:
          return getStringForKey(this.selectedLocale, "cancelStripe");
        case subs.subsciptionPaymentHandler.APP_STORE:
          return getStringForKey(this.selectedLocale, "cancelAppStore");
        case subs.subsciptionPaymentHandler.PLAY_STORE:
          return getStringForKey(this.selectedLocale, "cancelAppStore");
      }
    },
    titleText() {
      if (this.requiresCancellation) {
        return getStringForKey(this.selectedLocale, "cancelTitle");
      } else if (this.isUpgradeAccount) {
        return `${getStringForKey(
          this.selectedLocale,
          "updatedSubscription"
        )} ${this.firstName}!`;
      } else if (this.existingDealWasBest) {
        return getStringForKey(this.selectedLocale, "existingBest");
      } else if (this.existingAccountToSubscriber) {
        return getStringForKey(this.selectedLocale, "upgradedAccount");
      } else {
        return `${getStringForKey(this.selectedLocale, "thanksForJoining")} ${this.firstName
          }!`;
      }
    },
    isTallDisplay() {
      return window.innerWidth < window.innerHeight;
    },
  },
  methods: {
    ...mapActions({
      verify: "account/verifyCode",
      setLoading: "account/setLoading",
      sendText: "account/sendText",
      getDownloadLink: "account/downloadLink",
    }),

    validate(v) {
      this.number = v.number;
      this.valid = v.valid;
    },
    async download() {
      var link = await generateBranchLoginLink(this.fullName, this.email, this.originalLink);
      // console.log(link);
      if (link) {
        setTimeout(() => {
          window.open(link, "_self");
        });
      } else {
        this.error = true;
      }
      this.setLoading(false);
    },

    sendSMS1() {
      this.error = false;
      this.sent = false;
      this.errorMessage = '';
    
      let self = this;
      branch.sendSMS(
        this.number,
        {
          channel: "my.withutraining",
          feature: "Text-Me-The-App",
          data: {
            $custom_sms_text: getStringForKey(this.selectedLocale, "clickLink"),
            email: encodeURIComponent(this.email),
            name: encodeURIComponent(this.fullName),
            mode: "web",
            $web_only: false,
          },
        },
        { make_new_link: true },
        function (err) {
          console.log(err);
          if (err) {
            self.error = true;
            self.errorMessage = 'Failed to send SMS. Please try again later.';
          } else {
            self.sent = true;
          }
          self.setLoading(false);
        }
      );
    },
    
    async sendSMS() {
      this.error = false;
      this.sent = false;
      this.errorMessage = '';
    
      let link = await generateBranchLoginLink(this.fullName, this.email, this.originalLink);
      link && console.log(link);
    
      if (link) {
        let result = await this.sendText({
          number: this.number,
          url: link,
          message: getStringForKey(this.selectedLocale, "smsMessage"),
        });
        if (!result) {
          this.error = true;
          this.errorMessage = 'Failed to send SMS. Please try again later.';
        } else {
          this.sent = true;
        }
    
        this.setLoading(false);
      } else {
        this.error = true;
        this.errorMessage = 'Failed to generate link for SMS. Please try again later.';
        this.setLoading(false);
      }
    },
  },

  mounted() {
    console.log('Fire Once')
    this.$gtm.trackEvent({
      event: "SuccessfulRegistration",
      category: 'category',
      action: 'action',
      label: 'label',
      value: 'value',
    });
  },
  beforeMount() {
    // this.getDownloadLink();
    this.setLoading(false);
  },
};
