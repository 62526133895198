<template>
  <v-row class="fill-height" align="center" style="height: 100vh" no-gutters>
    <v-col cols="12" class="justify-center">
      <div
        class="d-flex flex-column text-center mx-auto align-center"
        style="max-width: 450px; font-size: 16px"
      >
        <v-img
          contain
          src="@/assets/tick.png"
          lazy-src="@/assets/tick-lo.png"
          position="center"
          max-width="100px"
        />
        <div style="width: 80%">
          <div class="font-weight-bold text-h5 mt-16 mb-4 primary--text">
            {{ titleText }}
          </div>
          <div v-if="!existingDealWasBest">
            <div
              v-if="requiresCancellation"
              class="mt-12 tertiaryGrey--text"
              style="font-weight: 500"
              v-html="requiresCancellationCopy"
            />
            <div
              v-else
              class="mt-12 tertiaryGrey--text"
              style="font-weight: 500"
            >
              {{
                this.showEmail
                  ? stringForKey("confirmationEmail")
                  : stringForKey("completedNextSteps")
              }}
            </div>
            <div
              class="mt-2 font-weight-bold primary--text"
              style="font-size: 14px"
              v-if="showEmail"
            >
              {{ email }}
            </div>
          </div>
          <div v-else>
            <div class="mt-4 main-text tertiaryGrey--text">
              Thanks for subscribing to WithU!
            </div>
          </div>
        </div>
        <div style="justify-content: center;" class="mt-4">
            <div
              style="height: 40px;display: inline-block;padding: 4px 4px 4px 4px;"
              @click="downloadIos"
              :loading="loading"
            >
              <img src=@/assets/apple-store-download.png height="40px">
            </div>
            <div
              style="height: 40px;display: inline-block; padding: 4px 4px 4px 4px;"
              @click="downloadAndroid"
              :loading="loading"
            >
              <img src=@/assets/play-store-download.png height="40px">
            </div>
          </div>
      </div>
      <v-alert
        :style="`position: absolute; top: ${
          isDesktop ? 0 : 50
        }px; left: 0px; right: 0px`"
        class="ma-0"
        type="warning"
        text
        dense
        v-if="showEmailError"
        >We are experiencing delays when sending emails to: Sky, Yahoo, AOL and
        Rocket email addresses. Please wait 30 minutes for your email to come
        through before contacting support</v-alert
      >
      <!-- <div class="text-center pa-2 text-caption mt-10" style="width: 100%">
        <div v-if="subscriptionId">
          <div class="font-weight-regular" style="color: #bcbcbc">
            Subscription ID:
            <span>{{ subscriptionId }}</span>
          </div>
        </div>
        <div
          class="font-weight-regular mx-auto"
          style="width: 80%; color: #bcbcbc"
        >
          <span>User ID: {{ userId }}</span>
        </div>
      </div> -->
    </v-col>
  </v-row>
</template>

<script>
import download from "../download";
import { mapGetters } from "vuex";
import { getStringForKey } from "@/translations/utilities";

export default {
  mixins: [download],
  computed: {
    ...mapGetters({
      selectedLocale: "account/getSelectedLocale",
      location: "account/country",
    }),
    showEmail() {
     
      return this.subscriptionId && !this.hasDeal;
    },
  },
  methods: {
    stringForKey(key) {
      return getStringForKey(this.selectedLocale, key);
    },
    showPhoneField() {
      return (
        this.location === "UK" ||
        this.location === "GB" ||
        this.location === "US" ||
        this.location === "USA"
      );
    },
  
    downloadIos() {
      setTimeout(() => {
        window.open(
          "https://apps.apple.com/us/app/withu-audio-fitness-app/id1487259038",
          "_self"
        );
      });
    },
    downloadAndroid() {
      setTimeout(() => {
        window.open(
          "https://play.google.com/store/apps/details?id=fit.withu.flutter.withu&hl=en_GB&gl=US",
          "_self"
        );
      });
    },
  },
};
</script>